<template>
  <div class="here marginT bg-fff py-5">
    <div class="mt-0">
      <img
        :src="getImage($route.params.unidade)"
        alt="schoolLogo.png"
        class="img-escola"
      />
    </div>
    <modalNovaTurma
      @atualizaDados="atualizaDados"
      :dadosEditaTurma="dadosEditaTurma"
    />
    <ModalConfirmacao
      :vontade="'esta'"
      :elementoVontade="'turma seja deletada'"
      :funcaoVontade="deletarTurma"
      @deletarTurma="deletarTurma"
    />
    <ModalPlanilha @atualizar-lista="atualizarLista" />
    <ModalVinculaProfessores
      :dadosVincularProfessor="dadosVincularProfessor"
      @atualizaDados="atualizaDados"
    />

    <div class="container">
      <div class="row">
        <div class="lupa col-md-12 mx-auto text-center d-flex my-4 px-0">
          <div class="row">
            <div class="col-md-12">
              <div class="position-relative">
                <img
                  class="position-absolute"
                  src="@/assets/icones/lupa.png"
                  alt="lupa.png"
                />
                <input
                  v-model="inputPesquisar"
                  type="text"
                  placeholder="Digite o nome ou o código para pesquisar "
                  class="shadow-sm border-default shadow-sm"
                />
              </div>
            </div>
            <!-- FUNCIONALIDADE FUTURA
                    <div class="col-md-7 d-flex date">
                        <div class="d-flex align-items-center justify-content-center">
                            <label for="" class="text-uppercase mr-2">filtrar por datas de:</label>
                        </div>
                        <div>
                            <input type="date" name="" id="" class="shadow-sm border-default shadow-sm">
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <label for="" class="text-uppercase mx-1">a</label>
                        </div>
                        <div>
                            <input type="date" name="" id="" class="shadow-sm border-default shadow-sm">
                        </div>
                        <div>
                            <button class="text-uppercase btn-style-default-less bg-222541 ml-3 color-fff shadow-sm">aplicar</button>
                        </div>
                    </div>
                    -->
          </div>
        </div>
        <div class="col-md-12 text-left pl-0 mb-3">
          <h2 class="title-dados pb-2">{{ $route.params.unidade }}</h2>
          <router-link :to="`/administrador/unidades-e-instituicoes`">
            <button class="mt-3" style="background: #0E5CAF; color: white; padding: 4px 19px; font-size: 16px">Voltar</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container bg-fff in-here shadow-sm">
      <div class="row pb-4">
        <div
          v-if="getTurmasLista.length != 0 || turmas.length > 0"
          class="
            col-md-11
            mx-auto
            h2
            row
            text-left
            border-bottom
            mt-5
            pb-2
            px-0
            d-flex
            justify-content-between
          "
        >
          <div class="col-md-1 p-0 d-flex justify-content-center">
            <h2>Nome</h2>
          </div>
          <div class="col-md-1 p-0 d-flex justify-content-center">
            <h2>Turma</h2>
          </div>
          <div class="col-md-1 p-0 d-flex justify-content-center">
            <h2>Alunos</h2>
          </div>
          <div class="col-md-2 p-0 d-flex justify-content-center">
            <h2>Cadastro</h2>
          </div>
          <div class="col-md-1 p-0 d-flex justify-content-center">
            <h2>Exercícios</h2>
          </div>
          <div class="col-md-2 p-0 d-flex justify-content-center">
            <h2>Professor</h2>
          </div>
          <div class="col-md-3 p-0 d-flex justify-content-center"></div>
        </div>
        <div class="col-md-11 mx-auto text-left mt-3 pb-2 px-0" v-else>
          <span>Ainda não existem turmas vinculadas à esta unidade</span>
        </div>
        <div
          class="
            col-md-11
            mx-auto
            h2
            row
            text-left
            border-bottom
            py-3
            px-0
            d-flex
            justify-content-between
          "
          v-for="(item, index) in turmas"
          :key="index"
        >
          <!-- <div class="col-md-1 p-0 d-flex c-center pl-0 d-flex justify-content-center">
            <h3>{{ `T ${item.cod.substr(3, 20)}` }}</h3>
          </div> -->
          <div
            class="
              col-md-1
              p-0
              d-flex
              c-center
              pl-0
              d-flex
              justify-content-center
            "
          >
            <h3>{{ item.name }}</h3>
          </div>
          <div
            class="
              col-md-1
              p-0
              d-flex
              c-center
              pl-0
              d-flex
              justify-content-center
            "
          >
            <h3>{{ item.cod }}</h3>
          </div>
          <div
            class="col-md-1 p-0 d-flex d-flex justify-content-center c-center"
          >
            <h3>{{ item.numero_alunos }}</h3>
          </div>
          <div
            class="col-md-2 p-0 d-flex d-flex justify-content-center c-center"
          >
            <h3>{{ item.created_at | trataDataHora }}</h3>
          </div>
          <div
            class="col-md-1 p-0 d-flex c-center d-flex justify-content-center"
          >
            <h3>{{ item.progresso }}</h3>
          </div>
          <div
            class="col-md-2 p-0 d-flex c-center d-flex justify-content-center"
          >
            <h3 v-if="item.professor">{{ item.professor.name }}</h3>
            <button
              v-else
              class="btn-style-default-less-2 color-fff bg-0e5daf tamanho"
              @click="vincularProfessor(item, 1)"
            >
              <i class="fas fa-share-alt"></i>
            </button>
          </div>
          <div class="col-md-3 d-flex justify-content-end pr-0 d-flex">
            <router-link
              tag="button"
              :to="`/administrador/unidades-e-instituicoes/${theRoute.idunidade}/${theRoute.unidade}/${item.id}/${item.cod}`"
              class="btn-style-default-less-2 color-fff bg-222541"
              ><i class="fa fa-eye" aria-hidden="true"></i
            ></router-link>
            <button
              v-if="$store.getters.getPerfil != 4"
              @click="EditarTurma(item)"
              class="btn-style-default-less-2 color-fff bg-fedd0a mx-2 tamanho"
            >
              <i class="fas fa-edit color-000"></i>
            </button>
            <button
              v-if="$store.getters.getPerfil == 1"
              @click="verificaDeletarTurmas(item)"
              class="
                btn-style-default-less-2
                color-fff
                bg-d42338
                mx-2
                p-3
                tamanho
              "
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
            <button
              v-if="$store.getters.getPerfil != 4"
              class="btn-style-default-less-2 color-fff bg-0e5daf tamanho"
              @click="vincularProfessor(item, 2)"
            >
              <i class="fas fa-share-alt"></i>
            </button>
          </div>
        </div>
        <div
          class="col-md-11 mx-auto d-flex justify-content-start mt-5 px-0"
          v-if="getUserDatas.profile_id != 4"
        >
          <button
            class="btn-style-default-less-2 color-fff bg-0e5daf"
            @click="novaTurma()"
          >
            nova turma
            <i class="icon-plus ml-1"
              ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
            /></i>
          </button>
          <button
            @click="showModal('modalNamePlanilha')"
            class="btn-style-default-less-2 color-fff bg-0e5daf"
          >
            Importar alunos
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modalNovaTurma from "@/components/modais/ModalNovaTurma";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import ModalVinculaProfessores from "@/components/modais/ModalVinculaProfessores";
import ModalPlanilha from "@/components/modais/ModalPlanilha";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      objTurmaDelete: {},
      lista: {
        file: require("../../assets/icones/iconeLogoTipo.png"),
      },
      obj: {
        type: "POST",
        token: this.$store.getters.getPerfilToken,
      },
      turmas: [],
      theRoute: this.$route.params,
      inputPesquisar: "",
      dadosEditaTurma: {},
      dadosVincularProfessor: {
        name: "",
        cod: "",
        unidade_id: "",
        user_id: "",
      },
    };
  },
  components: {
    modalNovaTurma,
    ModalConfirmacao,
    ModalVinculaProfessores,
    ModalPlanilha,
  },
  watch: {
    inputPesquisar: function () {
      this.turmas = this.filtrosDePesquisaDadosTurmas(this.inputPesquisar);
    },
    getUnidades: function () {
      this.turmas = this.filtrosDePesquisaDadosTurmas(this.inputPesquisar);
      this.getUnidades.forEach((element) => {
        if (element.id == this.$route.params.idunidade) {
          this.$store.commit("SET_TURMAS_LISTA", element.turmas);
          // this.turmas = element.turmas
        }
      });
    },
  },
  created() {},
  mounted() {
    this.atualizaDados();
  },
  computed: {
    ...mapGetters([
      "getUnidades",
      "getTurmasLista",
      "getUserDatas",
      "getPegaImg",
    ]),
  },
  methods: {
    getImage(unidade) {
      let file = this.getUnidades.filter((el) => el.name === unidade)[0].file;
      return file;
    },
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
    atualizaDados() {
      this.$store.dispatch("getUnidadesDB", {
        token: this.$store.getters.getPerfilToken,
      });
      this.$store.dispatch("getTurmasDB", this.$store.getters.getPerfilToken);
      this.getUnidades.forEach((element) => {
        if (element.id == this.$route.params.idunidade) {
          this.$store.commit("SET_TURMAS_LISTA", element.turmas);
          // this.turmas = element.turmas
        }
      });
      this.turmas = this.filtrosDePesquisaDadosTurmas("");
    },
    recebeTurmas() {
      this.$store
        .dispatch("getTurmasDB", this.$store.getters.getPerfilToken)
        .then((resolve) => {
          if (resolve.valid) {
            this.toastGlobal("success", "Turmas carregadas com sucesso.");
          } else {
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu e você não verá as turmas de forma correta... \n erro: ${resolve.msg}`
            );
          }
        });
    },
    verificaDeletarTurmas(item) {
      this.objTurmaDelete = item;
      this.showModal("modalNameConfirmacao");
    },
    atualizarLista() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/unidades/getTurmasEAlunos/${this.$route.params.idunidade}`,
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.turmas = response[0];
        },
        error: (response) => {
          console.log(response);
        },
      });
    },
    deletarTurma() {
      this.hideModal("modalNameConfirmacao");
      let objToDelete = {
        type: "DELETE",
        id: this.objTurmaDelete.id,
        token: this.$store.getters.getPerfilToken,
      };
      this.$store
        .dispatch("postDeleteTurmasDB", objToDelete)
        .then((resolve) => {
          if (resolve) {
            this.$store.dispatch("getUnidadesDB", {
              token: this.$store.getters.getPerfilToken,
            });
            this.$store.dispatch(
              "getTurmasDB",
              this.$store.getters.getPerfilToken
            );
            this.atualizaDados();
            // location.reload()
            this.toastGlobal(
              "success",
              `A turma ${val.cod}, pertencente ao colégio '${this.$route.params.unidade}', foi removida com sucesso`
            );
          } else {
            this.toastGlobal(
              "error",
              `Falha do sistema: Não foi possível remover a turma ${val.cod}, pertencente ao colégio '${this.$route.params.unidade}'`
            );

            //CRIAR O DELETE BASEADO NA PERMISSÂO>>>MODAL CONFIRMAÇÂO
          }
        });
    },
    EditarTurma(dado) {
      this.dadosEditaTurma = dado;
      this.showModal("modalNameNovasTurmas");
    },
    vincularProfessor(dados) {
      (this.dadosVincularProfessor.name = this.$route.params.unidade),
        (this.dadosVincularProfessor.cod = dados.cod),
        (this.dadosVincularProfessor.turma_id = dados.id);
      this.showModal("modalNameVincularProfessor");
    },
    novaTurma() {
      this.dadosEditaTurma = {};
      this.showModal("modalNameNovasTurmas");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

.icon-plus img {
  width: 15px;
}

input[type="date"] {
  width: 140px;
}

.date label {
  margin: 0;
  font-size: 10px;
}
/*classes fabio */
.img-escola {
  width: 100px;
}
.marginT {
  margin-top: -60px !important;
}
</style>
