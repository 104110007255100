<template>
<div id="AdministradorInstituicoes" class="pl-1">
    <transition-group name="fade">
        <div v-if="show && validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)" key="componentes-em-coordenadoria">
            <administradorVisualizarUnidades/>
        </div>
    </transition-group>
</div>
</template>

<script>
import administradorVisualizarUnidades from '@/components/administrador/AdministradorVisualizarUnidades'
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            show: false
        }
    },
    components: {
        administradorVisualizarUnidades,
    },
    mounted() {
        this.show = true
    },
    computed:{
        ...mapGetters(['getUserDatas'])
    },
}
</script>

<style scoped>

</style>
