<template>
  <modal
    id="modalVincularProfessor"
    name="modalNameVincularProfessor"
    :height="'auto'"
    :width="'50%'"
    :draggable="true"
    @opened="recebeDados()"
  >
    <div class="py-3">
      <label for="">
        <span class="color-42cc7e f-w-700">Vincular</span> professor à turma
        <span class="color-42cc7e f-w-700">{{
          dadosVincularProfessor.cod
        }}</span>
        da unidade
        <span class="color-42cc7e f-w-700">{{
          dadosVincularProfessor.name
        }}</span>
      </label>
      <div id="select-professor" class="row j-c-center">
        <div class="col-md-8">
          <div class="d-flex justofy-content-start">
            <label class="my-3 text-left">Professor</label>
          </div>
          <ValidationObserver
            ref="form"
            tag="form"
            class="text-left"
            @submit.prevent="vincula()"
          >
            <ValidationProvider
              rules="excluded:0|required"
              name="'Professor'"
              v-slot="{ errors }"
            >
              <select name="" id="" v-model="obj.user_id">
                <option class="text-uppercase" value="0" selected
                  >Selecionar</option
                >
                <option
                  v-for="(item, index) in getProfessores"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}
                </option>
              </select>
              <span class="span-status-validate">{{ errors[0] }}</span>
            </ValidationProvider>
            <div
              class="btns d-flex align-items-center justify-content-center mt-3"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click="hideModal('modalNameVincularProfessor')"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
              >
                salvar
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      obj: {
        turma_id: "",
        user_id: "",
      },
    };
  },
  props: ["dadosVincularProfessor"],
  computed: {
    ...mapGetters(["getProfessores", "getUnidades"]),
  },
  methods: {
    recebeDados() {
      this.obj.turma_id = this.dadosVincularProfessor.turma_id;
    },
    vincula() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          $.ajax({
            type: "POST",
            url: `${this.VUE_APP_CMS}api/turmaUser`,
            dataType: "json",
            data: this.obj,
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
            },
            success: (response) => {
              this.toastGlobal("success", `Ação realizada com sucesso`);

              this.hideModal("modalNameVincularProfessor");
              this.$store
                .dispatch("getUnidadesDB", {
                  token: this.$store.getters.getPerfilToken,
                })
                .then((resolve) => {
                  if (resolve) {
                    this.$emit("atualizaDados");
                    this.toastGlobal(
                      "success",
                      `Dados atualizados com sucesso`
                    );
                  } else {
                    this.toastGlobal(
                      "error",
                      `Atualiza a página! Você não está vendo os dados atualizados`
                    );
                  }
                });
            },
            error: (response) => {
              this.toastGlobal("error", `Algo de errado ocorreu ${response}`);
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
