<template>
  <modal
    id="modalNovasTurmas"
    name="modalNameNovasTurmas"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
    @opened="verificaDados('receber')"
    @closed="verificaDados('deletar')"
  >
    {{ dadosVincularProfessor }}
    <div class="container primeira-div-modal position-relative">
      <div class="py-4">
        <label class="pb-3 text-center"
          >{{ dadosEditaTurma ? "Editar/Criar" : "Nova" }} Turma</label
        >
        <div class="row text-left">
          <div class="col-md-8 mx-auto row">
            <div class="col-md-12 mx-auto row">
              <input type="text" disabled :value="$route.params.unidade" />
            </div>
            <ValidationObserver
              id="ourForm"
              ref="form"
              tag="form"
              class="text-left row px-3"
              @submit.prevent="postTurma()"
            >
              <div class="col-md-4">
                <label class="my-3">Código:</label>
                <input type="text" disabled :value="geraCodigoTurma()" />
              </div>
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="'Turma'"
                :bails="false"
                class="input-padding col-md-8"
                tag="div"
              >
                <div class="control" :class="classes">
                  <label class="my-3">Turma:</label>
                  <input
                    placeholder="Turma"
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="obj.data.cod"
                  />
                  <div class="text-left">
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="'Turma'"
                :bails="false"
                class="input-padding col-md-12"
                tag="div"
              >
                <div class="control" :class="classes">
                  <label class="my-3">Nome da turma:</label>
                  <input
                    placeholder="Nome da turma"
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="obj.data.name"
                  />
                  <div class="text-left">
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <div
                v-if="dadosEditaTurma.name"
                id="select-professor"
                class="col-md-12"
              >
                <label class="my-3">Professor</label>
                <select name="" id="" class="m-0" v-model="obj.data.user_id">
                  <option value="">Deixar em Aberto</option>
                  <option
                    v-for="(item, index) in getProfessores"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  col-md-12
                  mt-3
                "
              >
                <button
                  class="btn-style-default color-fff bg-d42338"
                  @click="hideModal('modalNameNovasTurmas')"
                  :disabled="load"
                >
                  cancelar
                </button>
                <button class="btn-style-default color-fff bg-0e5caf ml-3">
                  <span v-if="!load">salvar</span>
                  <div v-else class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      statusEdicao: "Salvar",
      codigoTurma: null,
      turma: "",
      obj: {
        data: {
          name: "",
          cod: "",
          unidade_id:
            this.$store.getters.getUserDatas.profile_id == 1
              ? this.$route.params.idunidade
              : "",
          user_id: null,
        },
        type: "POST",
        token: this.$store.getters.getPerfilToken,
        id: null,
      },
      show: false,
      load: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["getProfessores"]),
  },
  props: ["dadosEditaTurma"],
  methods: {
    geraCodigoTurma() {
      this.codigoTurma = `${this.funcaoVerificaLength(
        this.$route.params.idunidade
      )}${this.obj.data.cod}`;
      return this.codigoTurma.replace(/[^A-Za-z0-9]+/, "");
    },
    postTurma() {
      // this.load = true;
      this.obj.data.cod = this.codigoTurma;
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("postDeleteTurmasDB", this.obj)
            .then((resolve) => {
              if (resolve) {
                this.toastGlobal(
                  "success",
                  `A turma ${this.obj.data.cod}, pertencente ao colégio '${this.$route.params.unidade}', foi cadastrada com sucesso`
                );

                this.$store.dispatch("getUnidadesDB", {
                  token: this.$store.getters.getPerfilToken,
                });
                this.$store.dispatch(
                  "getTurmasDB",
                  this.$store.getters.getPerfilToken
                );
                this.obj.data.cod = "";
                this.$emit("atualizaDados");
                this.hideModal("modalNameNovasTurmas");
              } else {
                this.toastGlobal("error", `Erro: ${response}`);
                this.load = false;
              }
            });
        }
        setTimeout(() => {
          this.obj.data.name = "";
        }, 500);
      });
    },
    verificaDados(acao) {
      if (acao == "receber") {
        this.obj.data.cod = this.dadosEditaTurma.cod.substr(
          4,
          this.dadosEditaTurma.cod.length
        );
        this.obj.type = "PUT";
        this.obj.id = this.dadosEditaTurma.id;
      } else {
        this.obj.data.cod = "";
        this.obj.type = "POST";
        this.obj.id = null;
      }
    },
  },
};
</script>

<style></style>
